import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CaseTerm } from '../../../../app/shared/models/case/case-term.model';

export interface CaseTermState extends EntityState<CaseTerm> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'caseTerm' })
export class CaseTermStore extends EntityStore<CaseTermState, CaseTerm> {

  constructor() {
    super();
  }

}


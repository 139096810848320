import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from '../../../../app/shared/models/service/service.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';


@Injectable({
  providedIn: 'root'
})
export class ServiceDeDataService {

  constructor( private http: HttpClient) {}

  getAll(): Observable<Service[]> {
    return this.http.get<Service[]>(APP_CONFIG.api_endpoint + 'services_de');
  }

  getById(id: number): Observable<Service> {
    return this.http.get<Service>(APP_CONFIG.api_endpoint + `services_de/${id}`);
  }

  getBySlug(slug: string): Observable<Service> {
    return this.http.get<Service>(APP_CONFIG.api_endpoint + `services_de?slug=${slug}`)[0];
  }
}

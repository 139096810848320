import { NgModule } from '@angular/core';
import { RestangularModule } from 'ngx-restangular';

import { SharedModule } from '../../app/shared/shared.module';
import { RestangularConfigFactory } from './restangular-config.factory';

@NgModule({
  imports: [
    RestangularModule.forRoot([], RestangularConfigFactory),
    SharedModule
  ],
  declarations: [],
  providers: [],
  entryComponents: [
  ]
})

export class AppRequestsModule {
}

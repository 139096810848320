import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PagesStore } from './pages.store';
import { Observable, Observer } from 'rxjs';
import { Page } from '../../../../app/shared/models/page.model';
import { PageDataService } from '../../data-services/page/page.service';
import { PagesQuery } from './pages.query';

@Injectable({ providedIn: 'root' })
export class PagesService {

  constructor(private pagesStore: PagesStore,
              private pagesQuery: PagesQuery,
              private pageDataService: PageDataService) {
  }

  init(): Observable<Page[]> {
    return new Observable((observer: Observer<Page[]>) => {
      this.pageDataService.getAll().subscribe($page => {
        this.pagesStore.set($page);
        observer.next($page);
        observer.complete();
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { MenusService } from '../state/menus/menus.service';
import { PagesService } from '../state/pages/pages.service';
import { CaseTermService } from '../state/case-term/case-term.service';
import { EmployeeDeService } from '../state/employee-de/employee-de.service';
import { ServiceDeService } from '../state/service-de/service-de.service';
import { ServicePhService } from '../state/service-ph/service-ph.service';
import { JobsService } from '../state/jobs/jobs.service';
import { OffersService } from '../state/offers/offers.service';
import { CaseService } from '../state/case/case.service';
import { EmployeePhService } from '../state/employee-ph/employee-ph.service';
import { EmployeeTermService } from '../state/employee-term/employee-term.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(private menuService: MenusService,
              private pageService: PagesService,
              private caseTermsService: CaseTermService,
              private caseService: CaseService,
              private serviceDeService: ServiceDeService,
              private servicePhService: ServicePhService,
              private employeesDeService: EmployeeDeService,
              private employeesPhService: EmployeePhService,
              private employeeTermService: EmployeeTermService,
              private jobsService: JobsService,
              private offersService: OffersService) { }

  init(): Observable<any> {
    const observables = [this.menuService.init('de'), this.pageService.init(), this.serviceDeService.init(), this.servicePhService.init()];
    const joint = forkJoin(observables);

    return joint;
  }

  preload(): any {
    this.menuService.init('en').subscribe();
    this.caseTermsService.init().subscribe();
    this.caseService.init().subscribe();
    this.employeesDeService.init().subscribe();
    this.employeesPhService.init().subscribe();
    this.employeeTermService.init().subscribe();
    this.jobsService.init().subscribe();
    this.offersService.init().subscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { CaseTerm } from '../../../app/shared/models/case/case-term.model';
import { CaseTermQuery } from '../../../app/core/state/case-term/case-term.query';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Case } from '../../../app/shared/models/case/case.model';
import { CaseQuery } from '../../../app/core/state/case/case.query';
import { UiService } from '../../../app/core/state/ui';
import { Location } from '@angular/common';
import { forEach } from 'lodash';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/core/services/seo.service';


@Component ({
  selector: 'app-branchen-terms',
  templateUrl: './branchen-terms.component.html',
  styleUrls: ['./branchen-terms.component.scss']
})
export class BranchenTermsComponent implements OnInit {

  slug: string;
  term: CaseTerm;
  childTerm: CaseTerm;
  layouts: any[];
  childTermPosts: Case[];

  casePostsOrderd: Case[] = [];
  contentPath: string;

  constructor(private caseTermQuery: CaseTermQuery,
              private caseQuery: CaseQuery,
              private route: ActivatedRoute,
              private uiService: UiService,
              private location: Location,
              private titleService: Title,
              private router: Router,
              private seo: SeoService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: ParamMap) => {
      this.slug = params['term'];
      this.caseTermQuery.selectLoading().subscribe((loading) => {
        if (!loading) {
          this.caseTermQuery.selectEntity(entity => entity.slug === this.slug)
                            .subscribe(term => {
                              this.term = term;
                              if (this.term === undefined) {
                                this.router.navigate(['']);
                                return;
                              }
                              this.titleService.setTitle(this.term.name);
                             });

          if (this.term !== undefined) {
            this.caseTermQuery.selectEntity(entity => entity.parent === this.term.id)
                            .subscribe(term => {this.childTerm = term; });

            this.caseTermQuery.selctTermBySlugAndLangWithLayouts(this.slug, 'de')
                              .subscribe((term) => {
                                this.layouts = term.$$postsLayouts;
                                if (this.layouts) {
                                  forEach(this.layouts, (layout, key) => {
                                    if (layout.acf_fc_layout === 'full_width') {
                                      if (layout.case) {this.casePostsOrderd.push(layout.case); }
                                    } else if (layout.acf_fc_layout === 'one_to_two') {
                                      if (layout.case_small) {
                                        this.casePostsOrderd.push(layout.case_small);
                                      }

                                      if (layout.case_big) {
                                        this.casePostsOrderd.push(layout.case_big);
                                      }
                                    } else if (layout.acf_fc_layout === 'two_to_one') {
                                      if (layout.case_big) {
                                        this.casePostsOrderd.push(layout.case_big);
                                      }

                                      if (layout.case_small) {
                                        this.casePostsOrderd.push(layout.case_small);
                                      }
                                    }
                                  });
                                }
                              });

            this.caseQuery.selectLoading().subscribe((caseLoading) => {
              if (!caseLoading) {
                this.caseQuery.selectAll({filterBy: entity => entity.case_taxonomy.includes(this.childTerm.id)})
                .subscribe(data => this.childTermPosts = data);
              }
            });

            this.seo.setCanonicalUrl();
            this.seo.setSocialMediaTags({
              title: this.term.name,
              description: this.term.ffuf_term_acf.description,
              image: this.term.ffuf_term_acf.teaser_img
            });
          }
        }
      });
    });

    this.uiService.update('navbarBackground', 'bright');
    this.uiService.update('navbarLogo', 'red');
    this.uiService.update('showArrow', false);
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

  backgroundPosition(position: string): string {
    if (position === 'left') {
      return 'right';
    }
    return 'left';
  }

  backClicked(): void {
    this.location.back();
  }

}

import { Component, OnInit } from '@angular/core';
import { Page } from '../../../app/shared/models/page.model';
import { PagesQuery } from '../../../app/core/state/pages/pages.query';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UiService } from '../../../app/core/state/ui';


@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  pages: Page[];
  page: Page;
  slug: string;

  constructor(private pagesQuery: PagesQuery,
              private route: ActivatedRoute,
              private router: Router,
              private uiService: UiService) { }

  ngOnInit(): void {
    this.uiService.update('navbarLogo', 'red');
    this.uiService.update('navbarBackground', 'bright');

    this.route.params.subscribe((params: ParamMap) => {
      this.slug = params['slug'];
      this.page = this.pagesQuery.getBySlug(this.slug);
      if (this.page === undefined) {
        this.router.navigate(['']);
      }
    });
  }

}

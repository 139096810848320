import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StartPageComponent } from "./start-page/start-page.component";
import { SharedModule } from "src/app/shared/shared.module";
import { TemplatesComponent } from "./templates/templates.component";
import { BranchenComponent } from "./templates/branchen/branchen.component";
import { ManilaComponent } from "./templates/manila/manila.component";
import { ServicesPageComponent } from "./templates/services-page/services-page.component";
import { JobsPageComponent } from "./templates/jobs-page/jobs-page.component";
import { DataProtectionComponent } from "./templates/data-protection/data-protection.component";
import { RouterModule } from "@angular/router";
// import { ContactComponent } from './templates/contact/contact.component';
import { LottieAnimationViewModule } from "ng-lottie";
import { KatalogPageComponent } from "./templates/katalog-page/katalog-page.component";
import { NewsPageComponent } from "./templates/news-page/news-page.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TestComponent } from "src/app/pages/test/test.component";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [
    StartPageComponent,
    TemplatesComponent,
    BranchenComponent,
    ManilaComponent,
    ServicesPageComponent,
    JobsPageComponent,
    DataProtectionComponent,
    KatalogPageComponent,
    NewsPageComponent,
    TestComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    LottieAnimationViewModule.forRoot(),
    NgbModule,
    NgbModule.forRoot(),
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [],
})
export class PagesModule {}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OffersStore, OffersState } from './offers.store';
import { Offer } from '../../../../app/shared/models/offer/offer.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OffersQuery extends QueryEntity<OffersState, Offer> {

  constructor(protected store: OffersStore) {
    super(store);
  }

  selectAllByLang(lang: string): Observable<Offer[]> {
    return this.selectAll({filterBy: [entity => entity.ffuf_polylang.current_lang === lang ]});
  }

}

import 'zone.js/dist/zone-error';

export const environment = {
  production: false,
  name: 'local',
  hmr: false,
  cookieDomain: 'localhost:4200',

  // ↶↶ API Configurations ↷↷
  // Note: add base url's with trailing slash
  api: {
    default: 'https://backend.ffuf.de/wp-json/wp/v2/'
  },

  // ↶↶ Auth Configurations ↷↷
  auth: {
    authorizationUrl: 'https://auth.dev.pass.ffuf.de/authorization/',
    logoutUrl: 'https://auth.dev.pass.ffuf.de/authentication/logout/',
    tokenDetailsEndPoint: 'https://token.dev.pass.ffuf.de/details/',
    tokenEndPoint: 'https://token.dev.pass.ffuf.de/', // Used only for Pass 2.0 applications
    meEndpoint: 'https://app-api-user-d-a0939e.azurewebsites.net/me/',
    grantType: 'implicit_token_exchange',
    clientId: '0ed08cb7-7c35-476c-82a6-c9cda2954dac'
  },

  // ↶↶ GA Configuration ↷↷
  analytics: {}
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */

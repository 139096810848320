import { Component, OnInit, Input } from '@angular/core';
import { Page } from '../../../../app/shared/models/page.model';
import { ServicePhQuery } from '../../../../app/core/state/service-ph/service-ph.query';
import { Service } from '../../../../app/shared/models/service/service.model';
import { Observable } from 'rxjs';
import { UiService } from '../../../../app/core/state/ui';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/core/services/seo.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-manila',
  templateUrl: './manila.component.html',
  styleUrls: ['./manila.component.scss']
})
export class ManilaComponent implements OnInit {

  @Input() page: Page;
  file: boolean;
  services: Service[];
  contentPath: string;

  constructor(private servicePhQuery: ServicePhQuery,
              private uiService: UiService,
              private titleService: Title,
              private seo: SeoService,
              private router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle('FFUF Manila');
    this.file = false;
    this.services =  this.servicePhQuery.getAllByLang('de');
    this.seo.setCanonicalUrl();
    this.seo.setSocialMediaTags({
      title: this.page.title.rendered,
      description: this.page.ffuf_acf.layouts[0].text,
      image: 'https://backend.ffuf.de/wp-content/uploads/2019/11/makati.jpg'
    });
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

}

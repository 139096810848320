import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceRemove'
})

export class SpaceRemovePipe implements PipeTransform {

  transform(text: any): string {
    text = text.replace(/\s+/g, '');
    return text.toLowerCase();
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Job } from '../../../../app/shared/models/job/job.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';

@Injectable({
  providedIn: 'root'
})
export class JobDataService {

  constructor( private http: HttpClient ) {}

  getAll(): Observable<Job[]> {
    return this.http.get<Job[]>(APP_CONFIG.api_endpoint + `jobs_post_type?per_page=100`);
  }

  getById(id: number): Observable<Job> {
    return this.http.get<Job>(APP_CONFIG.api_endpoint + `jobs_post_type/${id}`);
  }

  getBySlug(slug: string): Observable<Job> {
    return this.http.get<Job>(APP_CONFIG.api_endpoint + `jobs_post_type?slug=${slug}`)[0];
  }
}

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Page } from '../../models/page.model';
import { PagesQuery } from '../../../../app/core/state/pages/pages.query';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerPage: Page;
  isBrowser: boolean;

  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId, private pagesQuery: PagesQuery) { 
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.footerPage = this.pagesQuery.getByLangAndTemplate('page-footer.php', 'de');
  }

  scrollUp(): void {
    if(this.isBrowser) {
      this.window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobsStore } from './jobs.store';
import { JobsQuery } from './jobs.query';
import { JobDataService } from '../../data-services/job/job.service';
import { Observable, Observer } from 'rxjs';
import { Job } from '../../../../app/shared/models/job/job.model';

@Injectable({ providedIn: 'root' })
export class JobsService {

  constructor(private jobsStore: JobsStore,
              private jobsQuery: JobsQuery,
              private jobDataService: JobDataService) {
  }

  init(): Observable<Job[]> {
    return new Observable((observer: Observer<Job[]>) => {
      this.jobDataService.getAll().subscribe( $jobs => {
        this.jobsStore.set($jobs);
        observer.next($jobs);
        observer.complete();
      });
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { InternalizationModule } from '@ffufm/ngx-i18n';
import { SerdesModule } from '@ffufm/ngx-serdes';
import { UiriModule } from '@ffufm/ngx-uiri';
import { UtilsModule } from '@ffufm/ngx-utils';

import { environment } from '../environments/environment';
import { I18N_CONFIG, SERDES_CONFIG, UIRI_CONFIG } from 'src/config/00-settings/lib.config';
import { AppRequestsModule } from 'src/config/01-requests/app-requests.module';
import { AppRoutingModule } from 'src/config/03-routing/app-routing.module';

@NgModule({
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    environment.name !== 'local' ? [] : [AkitaNgDevtools.forRoot(), AkitaNgRouterStoreModule.forRoot()],
    UtilsModule,
    SerdesModule.forRoot(SERDES_CONFIG),
    UiriModule.forRoot(UIRI_CONFIG),
    AppRequestsModule,
    AppRoutingModule,
    InternalizationModule.forRoot(I18N_CONFIG)
  ],
  exports: [RouterModule]
})
export class AppConfigModule {}

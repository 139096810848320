import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppConfigModule } from 'src/config/app-config.module';
import { AppComponent } from './app.component';
import { MainModule } from './main.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BranchenModule } from './branchen/branchen.module';
import { TeamModule } from './team/team.module';
import { JobsModule } from './jobs/jobs.module';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from './../environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    name: "FFUF Website Cookies",
    domain: environment.cookieDomain
  },
  palette: {
    popup: {
      background: '#06182b'
    },
    button: {
      background: '#cd1619'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'ffuf-layout',
  layouts: {
    "ffuf-layout": '<div class="container">{{messagelink}}{{compliance}}</div>'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message  m-0 py-2">{{message}}
      <a aria-label="learn more about our privacy policy" tabindex="0" class="cc-link" href="{{privacyPolicyHref}}"
      target="_blank">{{privacyPolicyLink}}</a>.
    </span>
    `,
  },
  content: {
    message: 'Um die Nutzung unsere Website zu ermöglichen, verwenden wir Cookies.<br> Durch die weitere Nutzung der Website stimmst du der Verwendung von Cookies zu Weitere Informationen zu Cookies findest du in unserer ',

    privacyPolicyLink: 'Datenschutzerklärung',
    privacyPolicyHref: 'http://localhost:4200/datenschutzerklaerung',
    allow: 'Verstanden',
    deny: 'false'
  }
};
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgbModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    SharedModule,
    MainModule,
    AppConfigModule,
    PagesModule,
    BranchenModule,
    TeamModule,
    JobsModule,
    FontAwesomeModule,
    HttpClientModule,
    ShareButtonsModule.withConfig({ debug: true }),
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamComponent } from './team.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {MatTabsModule} from '@angular/material';

@NgModule({
  declarations: [TeamComponent],
  imports: [CommonModule, NgbModule, MatTabsModule, NgbModule.forRoot(), RouterModule]
})
export class TeamModule { }

import { Component, OnInit, Input } from "@angular/core";
import { Page } from "../../../../app/shared/models/page.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-news-page",
  templateUrl: "./news-page.component.html",
  styleUrls: ["./news-page.component.scss"],
})
export class NewsPageComponent implements OnInit {
  @Input() page: Page;
  contentPath: string;
  items = [
    {
      title: "Neuer Name, bewährte Qualität",
      description:
        "Ab Januar 2025 treten wir unter dem Namen „OneQrew Digital Services“ auf, wobei unsere Verpflichtung zur exzellenten Dienstleistung unverändert bleibt.",
    },
    {
      title: "Verbesserte Services",
      description:
        "Durch die Integration in die OneQrew profitieren Sie von optimierten Prozessen und erweiterten Serviceleistungen, die auf die Bedürfnisse der modernen Arbeitswelt und technologisch anspruchsvollere Systeme zugeschnitten sind.",
    },
    {
      title: "Mehr Ressourcen und Erfahrung",
      description:
        "Als Teil der OneQrew haben wir Zugriff auf einen größeren Pool an Expertise und Ressourcen, was uns ermöglicht, Projekte noch effizienter und innovativer umzusetzen.",
    },
    {
      title: "Großes Netzwerk",
      description:
        "Profitieren Sie von unserem erweiterten Netzwerk innerhalb der Handwerksbranche, das Ihnen Zugang zu einer breiteren Palette an Dienstleistungen, Produkten und Expertise bietet.",
    },
  ];
  services = [
    {
      image: "https://backend.ffuf.de/wp-content/uploads/2024/10/service1.jpg",
      title: "Software-entwicklung",
      description:
        "Maßgeschneiderte Software-entwicklung ist und bleibt eine unserer zentralen Stärken.",
    },
    {
      image: "https://backend.ffuf.de/wp-content/uploads/2024/10/service2.jpg",
      title:
        "Con&shy;sult&shy;ing & Pro&shy;jek&shy;t&shy;man&shy;age&shy;ment",
      description:
        "Wir unterstützen umfassend bei der Planung und Umsetzung digitaler Projekte, um digitale Strategien erfolgreich zu realisieren.",
    },
    {
      image: "https://backend.ffuf.de/wp-content/uploads/2024/10/service3.jpg",
      title: "UX/UI Design",
      description:
        "Wir gestalten Ihre Anwendung intuitiv und vereinen Benutzerfreundlichkeit und Funktionalität.",
    },
    {
      image: "https://backend.ffuf.de/wp-content/uploads/2024/10/service4.jpg",
      title: "Bereitstellung von Entwicklungs-kapazität",
      description:
        "Für Entwicklungsprojekte bieten wir passende Ressourcen im passenden Modus.",
    },
    {
      image: "https://backend.ffuf.de/wp-content/uploads/2024/10/service5.jpg",
      title: "Service, Support & Betrieb",
      description:
        "Verlässlicher Service und Support, um den reibungslosen Betrieb digitaler Lösungen sicherzustellen.",
    },
  ];
  boxItems = [
    {
      title: "ERP",
      description:
        "Alle Geschäftsprozesse einfach und effizient automatisieren",
    },
    {
      title: "CAD/CAM",
      description:
        "Designprodukte detailgetreu planen, gestalten, visualisieren und realisieren",
    },
    {
      title: "Shop",
      description: "Verkaufserfolg und Reichweite im Online-Handel erhöhen",
    },
    {
      title: "DMS",
      description:
        "Geschäftsdokumente zentral verwalten und schnell darauf zugreifen",
    },
    {
      title: "PIM",
      description:
        "Produktinformationen zentral verwalten und in verschiedenen Kanälen vermarkten",
    },
    {
      title: "HR",
      description: "Personalprozesse effizienter und kostengünstiger machen",
    },
    {
      title: "Messenger",
      description:
        "Die interne Kommunikation schneller und effizienter gestalten",
    },
  ];

  fieldPass = [
    {
      title: "Cloud-Entwicklung",
      description:
        "Vollst ändige Ausrichtung auf native Cloud-Unterstützung, zur Erhöhung der Skalierbarkeit sowie der Erreichbarkeit und Ausfallsicherheit.",
    },
    {
      title: "Modularität",
      description:
        "Sinnvolle Trennung von Identity- und Access-Management (IAM) und message-basierten Kommunikation zwischen Systemen (CCL).",
    },
    {
      title: "Produkt-Integration",
      description:
        "Mögliche Anbindung an OneQrew ERP sowie Lösungen der Gruppenunternehmen.",
    },
    {
      title: "Sicherheit und Datenschutz",
      description:
        "Hohe Sicherheitsstandards inklusive regelmäßigen Pentests, um die sensiblen Daten innerhalb unserer Systeme zu schützen.",
    },
    {
      title: "Migrationsfähigkeit",
      description: "Sicherstellen von Abwärtskompatibilität zum FieldPass 4.",
    },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.contentPath = this.router.url.replace(/\//g, "_");
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from '../../../../app/shared/models/service/service.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';

@Injectable({
  providedIn: 'root'
})
export class ServicePhDataService {

  constructor( private http: HttpClient) {}

  getAll(): Observable<Service[]> {
    return this.http.get<Service[]>(APP_CONFIG.api_endpoint + 'services_ph');
  }

  getById(id: number): Observable<Service> {
    return this.http.get<Service>(APP_CONFIG.api_endpoint + `services_ph/${id}`);
  }

  getBySlug(slug: string): Observable<Service> {
    return this.http.get<Service>(APP_CONFIG.api_endpoint + `services_ph?slug=${slug}`)[0];
  }
}

import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Service } from '../../../../app/shared/models/service/service.model';

export interface ServicePhState extends EntityState<Service> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'service-ph' })
export class ServicePhStore extends EntityStore<ServicePhState, Service> {

  constructor() {
    super();
  }

}


import { Injectable } from '@angular/core';
import { Store, StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Page } from '../../../../app/shared/models/page.model';

export interface PagesState extends EntityState<Page> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pages' })
export class PagesStore extends EntityStore<PagesState, Page> {

  constructor() {
    super();
  }

}


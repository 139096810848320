import { Injectable } from '@angular/core';
import { EmployeeDeStore } from './employee-de.store';
import { EmployeeDeDataService } from '../../data-services/employee-de/employee-de.service';
import { EmployeeDeQuery } from './employee-de.query';
import { EmployeeDe } from '../../../../app/shared/models/emplyee/employee-de.model';
import { Observable, Observer } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class EmployeeDeService {

  constructor(private employeeDeStore: EmployeeDeStore,
              private employeeDeQuery: EmployeeDeQuery,
              private employeeDeDataService: EmployeeDeDataService) {
  }

  init(): Observable<EmployeeDe[]> {
    return new Observable((observer: Observer<EmployeeDe[]>) => {
      this.employeeDeDataService.getAll().subscribe($employee => {
        this.employeeDeStore.set($employee);
        observer.next($employee);
        observer.complete();
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries } from '@datorama/akita';
import { EmployeeTermStore, EmployeeTermState } from './employee-term.store';
import { EmployeeTerm } from '../../../../app/shared/models/emplyee/employee-term.model';
import { EmployeeDeQuery } from '../employee-de/employee-de.query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { map as _map, filter, includes } from 'lodash';
import { EmployeePhQuery } from '../employee-ph/employee-ph.query';
import { EmployeeDe } from '../../../../app/shared/models/emplyee/employee-de.model';
import { Post } from '../../../../app/shared/models/post.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeTermQuery extends QueryEntity<EmployeeTermState, EmployeeTerm> {

  constructor(protected store: EmployeeTermStore,
              private employeeDeQuery: EmployeeDeQuery,
              private employeePhQuery: EmployeePhQuery) {
    super(store);
  }

  selectAllTerms(lang: string, country: string): Observable<EmployeeTerm[]> {
    return combineQueries(
      [
        this.selectAll({filterBy: [
                        term => term.ffuf_term_polylang.current_lang === lang,
                        term => term.taxonomy === 'employees_' + country + '_taxonomy'
                      ]}),
        (country === 'de' ?  this.employeeDeQuery.selectAll() :  this.employeePhQuery.selectAll())
      ]
    ).pipe(
      map(([terms, employees]) => {
        return terms.map(term => {
          return {
            ...term,
            $$posts: filter(employees, (employee: EmployeeDe) => includes(_map(term.ffuf_term_posts, 'ID'), employee.id))
          };
        });
      }
    ));
  }
}

import { Component, OnInit } from "@angular/core";
import { Page } from "../../../app/shared/models/page.model";
import { PagesService } from "../../../app/core/state/pages/pages.service";
import { CaseTermService } from "../../../app/core/state/case-term/case-term.service";
import { CaseTerm } from "../../../app/shared/models/case/case-term.model";
import { ServiceDeService } from "../../../app/core/state/service-de/service-de.service";
import { Service } from "../../../app/shared/models/service/service.model";
import { PagesQuery } from "../../../app/core/state/pages/pages.query";
import { CaseTermQuery } from "../../../app/core/state/case-term/case-term.query";
import { ServiceDeQuery } from "../../../app/core/state/service-de/service-de.query";
import { Observable } from "rxjs";
import { UiService } from "../../../app/core/state/ui";
import { Title } from "@angular/platform-browser";
import { SeoService } from "src/app/core/services/seo.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-start-page",
  templateUrl: "./start-page.component.html",
  styleUrls: ["./start-page.component.scss"],
})
export class StartPageComponent implements OnInit {
  pages: Page[];
  startPage: Page;
  caseTerms$: Observable<CaseTerm[]>;
  services: Service[];
  contentPath: string;
  newsItems: string[];

  constructor(
    private pagesService: PagesService,
    private pagesQuery: PagesQuery,
    private caseTermService: CaseTermService,
    private caseTermQuery: CaseTermQuery,
    private serviceDeService: ServiceDeService,
    private serviceDeQuery: ServiceDeQuery,
    private uiService: UiService,
    private titleService: Title,
    private seo: SeoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Feil, Feil & Feil");
    this.uiService.update("navbarLogo", "red");
    this.uiService.update("navbarBackground", "bright");
    this.uiService.update("showArrow", false);

    this.startPage = this.pagesQuery.getByLangAndTemplate(
      "page-startpage.php",
      "de"
    );

    this.caseTerms$ = this.caseTermQuery.getOnlyParentsByLang("de");

    this.services = this.serviceDeQuery.getAllByLang("de");

    this.seo.setCanonicalUrl();
    this.seo.setSocialMediaTags({
      title: "Feil, Feil & Feil",
      description:
        "Unser Portfolio ist ausgerichtet auf die Entwicklung digitaler Lösungen für große dezentrale Handwerksunternehmen sowie Unternehmen rund um die Erstellung, den Betrieb und die Verwaltung von Immobilien.",
      image: "https://ffuf.de/assets/img/shared/katalog_hover.png",
    });

    this.newsItems = [
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
      "News!",
    ];
    this.contentPath = this.router.url.replace(/\//g, "_");
  }
}

import { Title } from './title.model';
import { Content } from './content.model';
import { FfufTerm } from './ffuf-term.model';


export class Page {
  id: number = null;
  slug: string = null;
  status: string = null;
  title: Title = null;
  content: Content = null;
  parent: number = null;
  menu_order: number = null;
  template: string = null;
  ffuf_acf: any = {};
  ffuf_terms: FfufTerm[] = [];
  ffuf_polylang: any = null;
  $$jobsLayouts: any;

  constructor({
    id,
    slug,
    status,
    title,
    content,
    parent,
    menu_order,
    template,
    ffuf_acf,
    ffuf_terms,
    ffuf_polylang,
    $$jobsLayouts
  }: Partial<Page>) {
    this.id = id;
    this.slug = slug;
    this.status = status;
    this.title = title;
    this.content = content;
    this.parent = parent;
    this.menu_order = menu_order;
    this.template = template;
    this.ffuf_acf = ffuf_acf;
    this.ffuf_terms = ffuf_terms;
    this.ffuf_polylang = ffuf_polylang;
    this.$$jobsLayouts = $$jobsLayouts || [];
  }
}

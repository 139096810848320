import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeTerm } from '../../../../app/shared/models/emplyee/employee-term.model';
import { EmployeePh } from '../../../../app/shared/models/emplyee/employee-ph.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';

@Injectable({
  providedIn: 'root'
})
export class EmployeePhTaxonomyDataService {

  constructor( private http: HttpClient) {}

  getAll(): Observable<EmployeeTerm[]> {
    return this.http.get<EmployeeTerm[]>(APP_CONFIG.api_endpoint + `employees_ph_taxonomy?per_page=100`);
  }

  getById(id: number): Observable<EmployeeTerm> {
    return this.http.get<EmployeeTerm>(APP_CONFIG.api_endpoint + `employees_ph_taxonomy/${id}`);
  }

  getBySlug(slug: string): Observable<EmployeeTerm> {
    return this.http.get<EmployeeTerm>(APP_CONFIG.api_endpoint + `employees_ph_taxonomy?slug=${slug}`)[0];
  }

  getPostsByTermId(termId: number): Observable<EmployeePh[]> {
    return this.http.get<EmployeePh[]>(APP_CONFIG.api_endpoint + `employees_ph?employees_ph_taxonomy=${termId}`);
    
  }
}

import { Injectable } from '@angular/core';
import { Query, QueryEntity, combineQueries } from '@datorama/akita';
import { CaseStore, CaseState } from './case.store';
import { Case } from '../../../../app/shared/models/case/case.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { find, map as _map, filter, includes, merge, clone } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CaseQuery extends QueryEntity<CaseState, Case> {

  constructor(protected store: CaseStore) {
    super(store);
  }

  selctCaseBySlugAndLangWithSimilarPosts(slug: string, lang: string): Observable<Case> {
    return combineQueries(
      [
        this.selectEntity(entity => entity.slug === slug && entity.ffuf_polylang.current_lang === lang),
        this.selectAll({filterBy: entity => entity.ffuf_polylang.current_lang === lang  })
      ]
    ).pipe(
      map(([casePost, posts]) => {
        return {
          ...casePost,
          $$similarPosts: _map(casePost.ffuf_acf.similar_posts, similarPost => {
            return find(posts, ['id', similarPost.post]);
          })
        };
      })
    );
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalizationService, Language } from '@ffufm/ngx-i18n';
import { TrackByFnService } from '@ffufm/ngx-utils';

@Component({
  selector: 'app-lang-picker',
  templateUrl: './lang-picker.component.html',
  styleUrls: ['./lang-picker.component.css']
})
export class LangPickerComponent implements OnInit, OnDestroy {
  languages: Language[];
  selectedLanguage: Language;
  private subs: any = {};

  constructor(
    private i18nService: InternalizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public trackByFnService: TrackByFnService
  ) {}

  ngOnInit(): void {
    this.subs['languages'] = this.i18nService
      .getLanguages$()
      .subscribe((languages: Language[]) => (this.languages = languages));

    this.subs['lang'] = this.i18nService
      .getSelectedLang$()
      .subscribe((lang: Language) => (this.selectedLanguage = lang));
  }

  ngOnDestroy(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }

  onLanguageChange(): void {
    this.i18nService.setSelectedLanguage(this.selectedLanguage.shortCode);
    if (Boolean(this.activatedRoute.snapshot.queryParams['lang'])) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { lang: this.selectedLanguage.shortCode }
      });
    }
  }
}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppInitService } from '../../../app/core/utils/app-init.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { response } from 'express';

@Injectable({ providedIn: 'root' })
export class InitialDataResolver implements Resolve<any> {
  isBrowser: boolean;

  constructor(private appInitService: AppInitService,
              @Inject(PLATFORM_ID) private platform_id) {
                this.isBrowser = isPlatformBrowser(this.platform_id);
              }

  resolve(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.appInitService.init().subscribe(data => {
        this.appInitService.preload();
        observer.next(data);
        observer.complete();
      });
    });

  }
}

import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ServiceDeStore } from './service-de.store';
import { ServiceDeQuery } from './service-de.query';
import { ServiceDeDataService } from '../../data-services/service-de/service-de.service';
import { Observable, Observer } from 'rxjs';
import { Service } from '../../../../app/shared/models/service/service.model';


@Injectable({ providedIn: 'root' })
export class ServiceDeService {

  constructor(private serviceDeStore: ServiceDeStore,
              private serviceDeQuery: ServiceDeQuery,
              private serviceDeDataService: ServiceDeDataService) {
  }

  init(): Observable<Service[]> {
    return new Observable((observer: Observer<Service[]>) => {
      this.serviceDeDataService.getAll().subscribe( $serviceDE => {
        this.serviceDeStore.set($serviceDE);
        observer.next($serviceDE);
        observer.complete();
      });
    });
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { Page } from "../../../../app/shared/models/page.model";
import { Job } from "../../../../app/shared/models/job/job.model";
import { Offer } from "../../../../app/shared/models/offer/offer.model";
import { Observable } from "rxjs";
import { JobsQuery } from "../../../../app/core/state/jobs/jobs.query";
import { OffersQuery } from "../../../../app/core/state/offers/offers.query";
import { PagesQuery } from "../../../../app/core/state/pages/pages.query";
import { Title } from "@angular/platform-browser";
import { SeoService } from "src/app/core/services/seo.service";
import {Router} from '@angular/router';

@Component({
  selector: "app-jobs-page",
  templateUrl: "./jobs-page.component.html",
  styleUrls: ["./jobs-page.component.scss"],
})
export class JobsPageComponent implements OnInit {
  @Input() page: Page;
  jobsIds: number[];
  myPage: Page;
  jobs$: Observable<Job[]>;
  offers$: Observable<Offer[]>;
  jobsLayouts: any[];
  contentPath: string;

  constructor(
    private jonbsQuery: JobsQuery,
    private offersQuery: OffersQuery,
    private pagesQuery: PagesQuery,
    private titleService: Title,
    private seo: SeoService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Karriere");
    this.offers$ = this.offersQuery.selectAllByLang("de");

    this.jobs$ = this.jonbsQuery.selectMany(this.jobsIds);

    this.pagesQuery.selectJobsPage("karriere").subscribe((data) => {
      this.myPage = data;
      this.jobsLayouts = data.$$jobsLayouts;
    });

    this.seo.setCanonicalUrl();
    this.seo.setSocialMediaTags({
      title: "Karriere",
      description:
        "Wir sind Feil, Feil & Feil. Du hast Lust mit uns gemeinsam digitale Projekte voranzutreiben? Dann zeig uns wer du bist und verrate uns, was du mit uns erreichen möchtest. Wir freuen uns auf deine Bewerbung.",
      image:
        "https://backend.ffuf.de/wp-content/uploads/2019/08/klarer-sieg.jpg",
    });
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

  IsSmall(index: number): Boolean {
    const Indexes = [0, 3, 4, 7, 8];
    if (Indexes.includes(index)) {
      return true;
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { CaseTermStore } from './case-term.store';
import { CaseTerm } from '../../../../app/shared/models/case/case-term.model';
import { Observable, Observer } from 'rxjs';
import { CaseTaxonomyDataService } from '../../data-services/case/case-taxonomy.service';

@Injectable({ providedIn: 'root' })
export class CaseTermService {

  constructor(private caseTermStore: CaseTermStore,
              private caseTermDataService: CaseTaxonomyDataService) {
  }

  init(): Observable<CaseTerm[]> {
    return new Observable((observer: Observer<CaseTerm[]>) => {
      this.caseTermDataService.getAll().subscribe($caseTerm => {
        this.caseTermStore.set($caseTerm);
        observer.next($caseTerm);
        observer.complete();
      });
    });
  }
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchenTermsComponent } from './branchen-terms/branchen-terms.component';
import { BranchenPostsComponent } from './branchen-posts/branchen-posts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [BranchenTermsComponent, BranchenPostsComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ]
})
export class BranchenModule { }

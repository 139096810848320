import { Injectable } from '@angular/core';
import { Query, QueryEntity, combineQueries } from '@datorama/akita';
import { PagesStore, PagesState } from './pages.store';
import { Page } from '../../../../app/shared/models/page.model';
import { map } from 'rxjs/operators';
import { find, map as _map, filter, includes, merge, clone } from 'lodash';
import { JobsQuery } from '../jobs/jobs.query';
import { Job } from '../../../../app/shared/models/job/job.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PagesQuery extends QueryEntity<PagesState, Page> {

  constructor(protected store: PagesStore,
              private jobsQuery: JobsQuery) {
    super(store);
  }

  getByLangAndTemplate (template: string, lang: string): Page {
    return find( this.getAll(), (page) => page.template === template && page.ffuf_polylang.current_lang === lang);
  }

  getBySlug (slug: string): Page {
    return find( this.getAll(), (page) => page.slug === slug);
  }

  selectJobsPage(slug: string): Observable<Page> {
    return combineQueries(
      [
        this.selectEntity(entity => entity.slug === slug),
        this.jobsQuery.selectAll()
      ]
    ).pipe(
      map(([page, jobs]) => {
        return {
          ...page,
          $$jobsLayouts: _map(page.ffuf_acf.jobs_layouts, layout => {
            const newLayout = clone(layout);
            if (newLayout.acf_fc_layout === 'full_width') {
              newLayout.job = find(jobs, ['id', layout.job]);
            } else {
              newLayout.job_small = find(jobs, ['id', layout.job_small]);
              newLayout.job_big = find(jobs, ['id', layout.job_big]);
            }
            return newLayout;
          })
        };
      }
    ));
  }

}

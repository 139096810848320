import { Injectable } from '@angular/core';
import { Store, StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { EmployeeDe } from '../../../../app/shared/models/emplyee/employee-de.model';

export interface EmployeeDeState extends EntityState<EmployeeDe> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employee' })
export class EmployeeDeStore extends EntityStore<EmployeeDeState, EmployeeDe> {

  constructor() {
    super();
  }

}


import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { EmployeeTermQuery } from '../core/state/employee-term/employee-term.query';
import { EmployeeTerm } from '../shared/models/emplyee/employee-term.model';
import { UiQuery, UiService } from '../core/state/ui';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  employeesDeTaxonomy: EmployeeTerm[];
  employeesPhTaxonomy: EmployeeTerm[];
  currentJustify: string;
  activeTab$: Observable<{activatedTeam: string}>;
  isBrowser: any;
  selectedButton: string;
  contentPath: string;
  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId, private employeeTermQuery: EmployeeTermQuery,
              private titleService: Title,
              private route: ActivatedRoute,
              private uiService: UiService,
              private router: Router) {
                this.currentJustify = 'end';
                this.isBrowser = isPlatformBrowser(this.platformId);
              }

  ngOnInit(): void {
    this.titleService.setTitle('Team');
    this.uiService.update('navbarLogo', 'white');
    this.uiService.update('navbarBackground', 'dark');
    this.uiService.update('showArrow', true);

    this.activeTab$ = this.route.paramMap
      .pipe(map(() => {
        if(this.isBrowser) {
          return this.window.history.state
        }
      }));
    this.selectedButton = 'button1';

    this.employeeTermQuery.selectAllTerms('de', 'de')
                            .subscribe(data => this.employeesDeTaxonomy = data);

    this.employeeTermQuery.selectAllTerms('de', 'ph')
                            .subscribe(data => this.employeesPhTaxonomy = data);
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

  toggleContent(button: string): void {
    this.selectedButton = button;
  }
  hoverFun(e: Event, width: number, height: number, positionX: number, positionY: number, element: Element ): void {
    if ( 10 < positionX && positionX < width - 10 && 15 < positionY  && positionY < height - 15  ) {
    const ax = (positionX - (width / 2)) / 5;
    const ay = (positionY - (height / 2)) / 7;
    element.setAttribute('style',
    'transform: rotateY('+ax+'deg) rotateX('+ay+'deg);-webkit-transform: rotateY('+ax+'deg) rotateX('+ay+'deg);-moz-transform: rotateY('+ax+'deg) rotateX('+ay+'deg)');
    }
  }

  hoverEnd(element: Element): void {
    element.setAttribute('style',
    'transform: rotateY(0deg) rotateX(0deg);-webkit-transform: rotateY(0deg) rotateX(0deg);-moz-transform: rotateY(0deg) rotateX(0deg)');
  }
}

import { version } from '../../../package.json';
import { environment } from 'src/environments/environment.js';

export const APP_CONFIG = {
  prefix: 'app',
  version,
  scopes: {
    APP_ACCESS: 'ffuf.access'
  },
  api_endpoint: environment.api.default
};

import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MenuItem } from '../../../../app/shared/models/menu/menu-item.model';


export interface MenusState extends EntityState<MenuItem> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'menus' })
export class MenusStore extends EntityStore<MenusState, MenuItem> {

  constructor() {
    super();
  }

}


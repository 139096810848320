import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { JobsStore, JobsState } from './jobs.store';
import { Job } from '../../../../app/shared/models/job/job.model';

@Injectable({ providedIn: 'root' })
export class JobsQuery extends QueryEntity<JobsState, Job> {

  constructor(protected store: JobsStore) {
    super(store);
  }

}

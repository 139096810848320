import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import { MenusStore } from './menus.store';
import { MenuItem } from '../../../../app/shared/models/menu/menu-item.model';
import { MenusQuery } from './menus.query';
import { MenuDataService } from '../../data-services/menu/menu.service';
import { Observable, Observer } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class MenusService {

  constructor(private menusStore: MenusStore,
              private menusQuery: MenusQuery,
              private menuDataService: MenuDataService) {
  }

  init(lang: string): Observable<MenuItem[]> {
    return new Observable((observer: Observer<MenuItem[]>) => {
      const slug =  lang === 'de' ? 'header-menu' : 'header-menu-en';
      this.menuDataService.getMenuElements(slug).subscribe(($menuItem) => {
        this.menusStore.set($menuItem);
        observer.next($menuItem);
        observer.complete();
      });
    });
  }
}

import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LottieAnimationViewModule } from "ng-lottie";
import { ModalModule } from "ngx-bootstrap";

import { AppLoaderComponent } from "./components/app-loader/app-loader.component";
// import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { CountryPickerComponent } from "./components/country-picker/country-picker.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LangPickerComponent } from "./components/lang-picker/lang-picker.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { MapComponent } from "./components/map/map.component";
import { ServicesComponent } from "./components/services/services.component";
import { SharedPageComponent } from "./components/shared-page/shared-page.component";
import { HtmlPipe } from "./pipes/html.pipe";
import { LimitToPipe } from "./pipes/limit-to.pipe";
import { NormalizePipe } from "./pipes/normalize.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { SpaceDeletePipe } from "./pipes/space-delete.pipe";
import { SpaceRemovePipe } from "./pipes/space-remove.pipe";

@NgModule({
  declarations: [
    CountryPickerComponent,
    LangPickerComponent,
    AppLoaderComponent,
    HtmlPipe,
    NormalizePipe,
    SpaceDeletePipe,
    MainMenuComponent,
    SafeHtmlPipe,
    ServicesComponent,
    SharedPageComponent,
    FooterComponent,
    SpaceRemovePipe,
    // ContactFormComponent,
    LimitToPipe,
    MapComponent,
  ],
  exports: [
    ModalModule,
    CountryPickerComponent,
    LangPickerComponent,
    AppLoaderComponent,
    TranslateModule,
    MainMenuComponent,
    FooterComponent,
    HtmlPipe,
    NormalizePipe,
    SpaceDeletePipe,
    SafeHtmlPipe,
    ServicesComponent,
    SharedPageComponent,
    // ContactFormComponent,
    LimitToPipe,
    MapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    RouterModule,
    NgbModule,
    NgbModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDuOueyGxbZgfIXbF9zrN61_MbtUds0uPY",
    }),
  ],
  providers: [],
})
export class SharedModule {}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { BranchenPostsComponent } from "../../app/branchen/branchen-posts/branchen-posts.component";
import { BranchenTermsComponent } from "../../app/branchen/branchen-terms/branchen-terms.component";
import { JobsComponent } from "../../app/jobs/jobs.component";
import { MainComponent } from "../../app/main.component";
import { StartPageComponent } from "../../app/pages/start-page/start-page.component";
import { TemplatesComponent } from "../../app/pages/templates/templates.component";
import { TeamComponent } from "../../app/team/team.component";
import { InitialDataResolver } from "./resolvers/initial-data-resolver";
import { TestComponent } from "src/app/pages/test/test.component";

const routes: Routes = [
  { path: "testing", component: TestComponent },
  {
    path: "",
    component: MainComponent,
    resolve: {
      init: InitialDataResolver,
    },
    data: {
      pageTitle: "FFUF",
    },
    children: [
      {
        path: "",
        component: StartPageComponent,
      },
      {
        path: "team",
        component: TeamComponent,
        data: { activeTab: "" },
      },
      {
        path: ":slug",
        component: TemplatesComponent,
      },
      {
        path: "branchen/:term",
        component: BranchenTermsComponent,
      },
      {
        path: "branchen/:term/:slug",
        component: BranchenPostsComponent,
      },
      {
        path: "karriere/:slug",
        component: JobsComponent,
      },
      // {
      //   path: 'jobs/:slug',
      //   loadChildren: '../../../../../app/pages/cases.module#CasesModule',
      //   data: {
      //     pageTitle: 'Pass User'
      //   }
      // },
      // {
      //   path: 'karriere/:slug',
      //   loadChildren: '../../../../../app/pages/cases.module#CasesModule',
      //   data: {
      //     pageTitle: 'Pass User'
      //   }
    ],
  },
  // {path: 'start', component: StartPageComponent, pathMatch: 'full'},
  // { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
  constructor() {}
}

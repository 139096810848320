import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalize'
})
export class NormalizePipe implements PipeTransform {

  transform(str: string): string {
    const combining = /[\u0300-\u036F]/g;
    str = str.normalize('NFKD').replace(combining, '');
    return str;
  }

}

import { Component, OnInit, ElementRef } from '@angular/core';
import { Page } from '../../models/page.model';
import { PagesService } from '../../../../app/core/state/pages/pages.service';
import { EmployeeDe } from '../../models/emplyee/employee-de.model';
import { EmployeeDeService } from '../../../../app/core/state/employee-de/employee-de.service';
import { PagesQuery } from '../../../../app/core/state/pages/pages.query';
import { EmployeeDeQuery } from '../../../../app/core/state/employee-de/employee-de.query';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-shared-page',
  templateUrl: './shared-page.component.html',
  styleUrls: ['./shared-page.component.scss']
})
export class SharedPageComponent implements OnInit {
  sharedPage: Page;
  teamLeadrsIds: number[];
  teamLeadrs$: Observable<EmployeeDe[]>;

  constructor(private pagesService: PagesService,
              private pagesQuery: PagesQuery,
              private employeeDeQuery: EmployeeDeQuery,
              private employeeDeService: EmployeeDeService) { }

  ngOnInit(): void {

    this.employeeDeService.init().subscribe();
    this.sharedPage = this.pagesQuery.getByLangAndTemplate('page-shared.php', 'de');

    this.teamLeadrsIds = this.sharedPage.ffuf_acf.team_leaders.map((employee: any) => {
      return employee.team_leader;
    });

    this.teamLeadrs$ = this.employeeDeQuery.selectMany(this.teamLeadrsIds);
  }
}

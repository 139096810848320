import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PagesQuery } from '../../../app/core/state/pages/pages.query';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})



export class TestComponent implements OnInit {
  lottieConfig0: Object;
  lottieConfig1: Object;
  lottieConfig2: Object;
  lottieConfig3: Object;
  lottieConfig4: Object;
  lottieConfig5: Object;
  anim: any;
  directions: number[] = [];
  playing: boolean[] = [];
  animations: any[] = [];
  elRef: ElementRef;
  lastScrollTop: number;
  isBrowser: boolean;

  userId: any;
  title: string;
  data;

  startPage;

  constructor(private http: HttpClient,
              private pagesQuery: PagesQuery) { 

  }

  ngOnInit() {
    this.lottieConfig0 = {
      path: ' assets/animation/app.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    const test = new Observable(obs => {
      obs.next("hello");
    })

    const fake = of(test).pipe(delay(2000));
    fake.subscribe(data => console.log(data));


  }

  handleAnimation(anim: any): void {
    this.anim = anim;
    this.animations.push(this.anim);
    this.directions.push(1);
    this.playing.push(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.animations[0].setDirection(this.directions[0]);
      this.animations[0].play();

      this.animations[3].setDirection(this.directions[0]);
      this.animations[3].play();
    }, 1500);
  }

}

import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import {Location, isPlatformBrowser} from '@angular/common';
import { MenuItem } from '../../models/menu/menu-item.model';
import { MenusService } from '../../../../app/core/state/menus/menus.service';
import { MenusQuery } from '../../../../app/core/state/menus/menus.query';
import { UiQuery, UiService } from '../../../../app/core/state/ui';
import { query, style } from '@angular/animations';
import { WINDOW } from '@ng-toolkit/universal';
import {Router} from '@angular/router';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  isCollapsed: Boolean = false;
  menu: MenuItem[];
  background: string;
  menuBackgroundColor: string;
  logo: string;
  showArrow: boolean;
  toggleClicked: boolean;
  innerWidth: number;
  opacity: number;
  boxShadow: string;
  padding: string;
  scrolledUp: boolean;

  @ViewChild('navFixed', { read: ElementRef, static: false }) nav: ElementRef;
  isBrowser: boolean;

  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId, private menuService: MenusService,
              private router: Router,
              private menuQuey: MenusQuery,
              private uiService: UiService,
              private uiQuery: UiQuery,
              private location: Location) {
                this.isBrowser = isPlatformBrowser(this.platformId);
              }

  ngOnInit(): void {
    this.scrolledUp = false;
    this.menuService.init('de')
        .subscribe((data) => {
          this.menu = data;
        });
    this.uiQuery.select('navbarLogo')
                .subscribe(data => {
                  this.logo = (data !== undefined ? data : 'White');
                });

    this.uiQuery.select('navbarBackground')
                .subscribe(data => this.background = data);

    this.uiQuery.select('showArrow')
                .subscribe(data => {this.showArrow = data; });

    this.uiQuery.select('menuBackgroundColor')
                .subscribe(data => {this.menuBackgroundColor = data; });

    this.toggleClicked = false;
    this.opacity = 0;
  }

  skipToContent(): void {
    const contentPath = this.router.url.replace(/\//g, '_');
    document.querySelector<HTMLElement>('#contentId' + contentPath).focus();
  }

  backClicked(): void {
    this.location.back();
    this.uiService.update('showArrow', false);
  }

  toggled(tooglde: boolean): boolean {
    tooglde = (tooglde === true ? false : true );
    return tooglde;
  }

  collapseClose(): void {
    this.isCollapsed = false;
  }

  @HostListener('load', ['$event'])
  getBackgroundColor(): string {
    if ( this.background === 'transparent' ) {
      return this.menuBackgroundColor ;
    } else if ( this.background === 'bright' ) {
      return '#ffffff' ;
    } else if (this.background === 'dark') {
      return '#061624' ;
    }
  }
  onLoad(): void {
    if(this.isBrowser) {
      this.innerWidth = this.window.innerWidth;
    }
  }

  returnHex(rgb: number): string {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  rgbTOHex(r: number, g: number, b: number): string {
    const red = this.returnHex(r);
    const green = this.returnHex(g);
    const blue = this.returnHex(b);
    return '#06192b0d 0px 20px 20px';
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if(this.isBrowser) {
      const scrollPosition = this.window.pageYOffset;
      this.innerWidth = this.window.innerWidth;
      setTimeout(() => {
        this.opacity = (this.innerWidth > 768 ? (scrollPosition) / 90 : (scrollPosition) / 70 );
        this.boxShadow = (this.opacity >= 1 ? this.rgbTOHex(226, 218, 218) : 'none');
        this.padding = (this.opacity >= 1 ? '24px 0' : '44px 0');
        this.scrolledUp = (this.opacity >= 1 ? true : false);
      }, 0);
    }
  }
}

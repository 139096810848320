import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EmployeeTerm } from '../../../../app/shared/models/emplyee/employee-term.model';

export interface EmployeeTermState extends EntityState<EmployeeTerm> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employeeTerm' })
export class EmployeeTermStore extends EntityStore<EmployeeTermState, EmployeeTerm> {

  constructor() {
    super();
  }

}


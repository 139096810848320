import { Injectable } from '@angular/core';
import { Store, StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Job } from '../../../../app/shared/models/job/job.model';

export interface JobsState extends EntityState<Job> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'jobs' })
export class JobsStore extends EntityStore<JobsState, Job> {

  constructor() {
    super();
  }

}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader', // <-- replace `app` prefix with the one you're using
  template: `
    <div id="app-loader">
      <div class="loader-box">
        <img src="assets/img/ffuf-logo.png" alt="ffuf-logo"/>
        <div class="loader"></div>
      </div>
    </div>
  `
})

export class AppLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

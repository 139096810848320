import { Component, OnInit, Input } from '@angular/core';
import { CaseTerm } from '../../../../app/shared/models/case/case-term.model';
import { Page } from '../../../../app/shared/models/page.model';
import { CaseTermQuery } from '../../../../app/core/state/case-term/case-term.query';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/core/services/seo.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-branchen',
  templateUrl: './branchen.component.html',
  styleUrls: ['./branchen.component.scss']
})
export class BranchenComponent implements OnInit {

  @Input() page: Page;
  caseTerms$: Observable<CaseTerm[]>;
  contentPath: string;

  constructor(private caseTermQuery: CaseTermQuery,
              private titleService: Title,
              private seo: SeoService,
              private router: Router) { }

  ngOnInit(): void {
    this.caseTerms$ = this.caseTermQuery.getOnlyParentsByLang('de');
    this.titleService.setTitle('Unsere Branchen');
    this.seo.setCanonicalUrl();
    this.seo.setSocialMediaTags({
      title: this.page.title.rendered,
      description: this.page.ffuf_acf.text,
      image: 'https://backend.ffuf.de/wp-content/uploads/2019/09/Preview-Tourenplanung.jpg'
    });
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

}

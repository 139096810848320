import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServiceDeStore, ServiceDeState } from './service-de.store';
import { Service } from '../../../../app/shared/models/service/service.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceDeQuery extends QueryEntity<ServiceDeState, Service> {

  constructor(protected store: ServiceDeStore) {
    super(store);
  }

  getAllByLang(lang: string): Service[] {
    return this.getAll().filter(service => service.ffuf_polylang.current_lang === lang);
  }

}

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { InternalizationService } from '@ffufm/ngx-i18n';
import { filter, map, mergeMap } from 'rxjs/internal/operators';
import { I18N_CONFIG } from '../config/00-settings/lib.config';
import { UiService } from './core/state/ui';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  isBrowser: boolean;
  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private i18nService: InternalizationService,
    private uiService: UiService
  ) {
    this.routeEventChangeListener();
    this.queryParamChangeListener();
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    setTimeout(() => this.uiService.update('appLoading', false));
  }

  queryParamChangeListener(): void {
    this.activatedRoute.queryParams.subscribe((params: ParamMap) => {
      if (I18N_CONFIG.enabled && Boolean(params['lang'])) {
        if (
          this.i18nService.getSelectedLang().shortCode !== undefined &&
          this.i18nService.getSelectedLang().shortCode !== params['lang']
        ) {
          this.i18nService.setSelectedLanguage(params['lang']);
        }
      }
    });
  }

  routeEventChangeListener(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        this.titleService.setTitle(Boolean(data['pageTitle']) ? data['pageTitle'] : '');
        if (!(data instanceof NavigationEnd)) {
          if(this.isBrowser) {
            this.window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        }
      });
  }
}

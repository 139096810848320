import { Component, OnInit, Input } from '@angular/core';
import { Page } from '../../../../app/shared/models/page.model';
import {Router} from '@angular/router';


@Component({
  selector: 'app-katalog-page',
  templateUrl: './katalog-page.component.html',
  styleUrls: ['./katalog-page.component.scss']
})
export class KatalogPageComponent implements OnInit {

  @Input() page: Page;
  contentPath: string;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../../../app/shared/models/page.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';


@Injectable({
  providedIn: 'root'
})
export class PageDataService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Page[]> {
    return this.http.get<Page[]>(APP_CONFIG.api_endpoint + `pages?per_page=100`);
  }

  getById(id: number): Observable<Page> {
    return this.http.get<Page>(APP_CONFIG.api_endpoint + `pages/${id}`);
  }

  getBySlug(slug: string): Observable<Page> {
    return this.http.get<Page>(APP_CONFIG.api_endpoint + `pages?slug=${slug}`)[0];
  }
}

import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Service } from '../../models/service/service.model';
import { forEach } from 'lodash';
import {Router} from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})

export class ServicesComponent implements OnInit {

  @Input() services: Service[];
  @Input() startPage: boolean;
  @Input() firstLoad: boolean;
  @Input() manila: boolean;


  activeService: number;

  lottieConfig0: Object;
  lottieConfig1: Object;
  lottieConfig2: Object;

  lottieConfig3: Object;
  lottieConfig4: Object;
  lottieConfig5: Object;

  anim: any;
  dir: number;
  path: string;
  first: boolean;
  animations: any[] = [];

  animPath1: string;
  animPath2: string;
  animPath3: string;

  contentPath: string;


  constructor(private router: Router) { }

  ngOnInit(): void {
    this.activeService = 0;
    this.dir = 1;
    this.first = true;

    this.animPath1 = (this.manila === true ? 'assets/animation/communication.json' : 'assets/animation/app.json');
    this.animPath2 = (this.manila === true ? 'assets/animation/culture.json' : 'assets/animation/cloud.json');
    this.animPath3 = (this.manila === true ? 'assets/animation/scalability.json' : 'assets/animation/robot.json');

    this.lottieConfig0 = {
      path: this.animPath1,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig1 = {
      path: this.animPath2,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig2 = {
      path: this.animPath3,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig3 = {
      path: this.animPath1,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig4 = {
      path: this.animPath2,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig5 = {
      path: this.animPath3,
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };
    this.contentPath = this.router.url.replace(/\//g, '_');

  }

  @HostListener('click', ['$event'])
  serviceClick( serviceIndex: number, event: any): void {
    console.log('serviceIndex: ', serviceIndex);
    if (serviceIndex !== this.activeService) {
      let clicked = true;
      const currentAnimation_1 = this.animations[this.activeService];
      const currentAnimation_2 = this.animations[this.activeService + 3];
      if (event) {
        this.dir = this.changeDir(this.dir);
        currentAnimation_1.setDirection(this.dir);
        currentAnimation_1.play();
        currentAnimation_2.setDirection(this.dir);
        currentAnimation_2.play();
        currentAnimation_1.addEventListener('complete', () => {
          if (clicked) {
            this.activeService = serviceIndex;
            this.dir = this.changeDir(this.dir);
            const nextAnimation_1 = this.animations[serviceIndex];
            const nextAnimation_2 = this.animations[serviceIndex + 3];
            nextAnimation_1.setDirection(this.dir);
            nextAnimation_1.play();
            nextAnimation_2.setDirection(this.dir);
            nextAnimation_2.play();
            clicked = false;
          }
        });
      }
    }
  }

  handleAnimation(anim: any): void {
    this.anim = anim;
    this.animations.push(this.anim);
    if (anim.fileName === 'app' || anim.fileName === 'communication') {
      this.anim.play();
    }
  }

  changeDir(dir: number): number {
    return (dir === 1 ? -1 : 1);
  }

  stop(): void {
    this.anim.stop();
  }

  play(): void {
      this.anim.play();
  }

  pause(): void {
      this.anim.pause();
  }

}

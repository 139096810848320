import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { EmployeePhStore, EmployeePhState } from './employee-ph.store';
import { EmployeePh } from '../../../../app/shared/models/emplyee/employee-ph.model';

@Injectable({ providedIn: 'root' })
export class EmployeePhQuery extends QueryEntity<EmployeePhState, EmployeePh> {

  constructor(protected store: EmployeePhStore) {
    super(store);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { CaseTerm } from '../../../app/shared/models/case/case-term.model';
import { Case } from '../../../app/shared/models/case/case.model';
import { CaseTermQuery } from '../../../app/core/state/case-term/case-term.query';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CaseQuery } from '../../../app/core/state/case/case.query';
import { UiService } from '../../../app/core/state/ui';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-branchen-posts',
  templateUrl: './branchen-posts.component.html',
  styleUrls: ['./branchen-posts.component.scss']
})
export class BranchenPostsComponent implements OnInit {
  termSlug: string;
  slug: string;
  term: CaseTerm;
  post: Case;
  similarPosts: Case[];
  backgroundPosition: string;
  content: string;


  constructor(private caseTermQuery: CaseTermQuery,
              private caseQuery: CaseQuery,
              private route: ActivatedRoute,
              private uiService: UiService,
              private titleService: Title,
              private router: Router,
              private seo: SeoService,) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: ParamMap) => {
      this.termSlug = params['term'];
      this.slug = params['slug'];

      // tslint:disable-next-line: restrict-plus-operands
      this.caseQuery.selectLoading().subscribe((loading) => {
        if (!loading) {
          this.caseQuery.selectEntity(entity => entity.slug === this.slug && entity.ffuf_polylang.current_lang === 'de')
                        .subscribe((post) => {
                          this.post = post;
                          if (this.post === undefined) {
                            this.router.navigate(['']);
                            return;
                          }
                          this.titleService.setTitle(this.post.title.rendered);
                          this.backgroundPosition = (post.ffuf_acf.teaser.teaser_text_position.value === 'left' ? 'right' : 'left');
                        });
          if (this.post !== undefined) {
            this.uiService.update('navbarBackground', 'transparent');
            this.uiService.update('navbarLogo', this.post.ffuf_acf.logoColor);
            this.uiService.update('menuBackgroundColor', this.post.ffuf_acf.menuBackgroundColor);
            this.uiService.update('showArrow', true);
            this.uiService.update('fixedBoxShadow', false);

            this.caseQuery.selctCaseBySlugAndLangWithSimilarPosts(this.slug, 'de')
                              .subscribe((post) => {
                                this.similarPosts = post.$$similarPosts;
                              });

            this.caseTermQuery.selectLoading().subscribe((loadingTerms) => {
              if (!loadingTerms) {
                this.caseTermQuery.selectEntity(entity => entity.slug === this.termSlug)
                              .subscribe((term) => { this.term = term; } );
              }
            });

            this.seo.setCanonicalUrl();
            this.seo.setSocialMediaTags({
              title: this.post.title.rendered,
              description: this.post.ffuf_acf.layouts[0].text,
              image: this.post.ffuf_acf.teaser.mobileBild
            });
          }
        }
      });
    });
    this.content = this.router.url.replace(/\//g, '_');
    console.log('idBranchenPosts: ' + this.content);
  }

}

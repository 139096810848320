import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CaseTerm } from '../../../../app/shared/models/case/case-term.model';
import { Case } from '../../../../app/shared/models/case/case.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';

@Injectable({
  providedIn: 'root'
})
export class CaseTaxonomyDataService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<CaseTerm[]> {
    return this.http.get<CaseTerm[]>(APP_CONFIG.api_endpoint + `case_taxonomy?per_page=100`);
  }

  getById(id: number): Observable<CaseTerm> {
    return this.http.get<CaseTerm>(APP_CONFIG.api_endpoint + `case_taxonomy/${id}`);
  }

  getBySlug(slug: string): Observable<CaseTerm> {
    return this.http.get<CaseTerm>(APP_CONFIG.api_endpoint + `case_taxonomy?slug=${slug}`)[0];
  }

  getAllNotChildren(): Observable<CaseTerm[]> {
    return this.http.get<CaseTerm[]>(APP_CONFIG.api_endpoint + `case_taxonomy?parent=0`);
  }

  getChildrenByParentID(parentId: number): Observable<CaseTerm[]> {
    return this.http.get<CaseTerm[]>(APP_CONFIG.api_endpoint + `case_taxonomy?parent=${parentId}`);
  }

  getPostsByTermId(termId: number): Observable<Case[]> {
    return this.http.get<Case[]>(APP_CONFIG.api_endpoint + `case_post_type?case_taxonomy=${termId}`);
    
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MenusStore, MenusState } from './menus.store';
import { MenuItem } from '../../../../app/shared/models/menu/menu-item.model';

@Injectable({
  providedIn: 'root'
})
export class MenusQuery extends QueryEntity<MenusState, MenuItem> {

  constructor(protected store: MenusStore) {
    super(store);
  }

}

import { Injectable } from '@angular/core';
import { EmployeePhStore } from './employee-ph.store';
import { EmployeePhQuery } from './employee-ph.query';
import { EmployeePhDataService } from '../../data-services/employee-ph/employee-ph.service';
import { EmployeePh } from '../../../../app/shared/models/emplyee/employee-ph.model';
import { Observable, Observer } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class EmployeePhService {

  constructor(private employeePhStore: EmployeePhStore,
              private employeePhQuery: EmployeePhQuery,
              private employeePhDataService: EmployeePhDataService) {
  }

  init(): Observable<EmployeePh[]> {
    return new Observable((observer: Observer<EmployeePh[]>) => {
      this.employeePhDataService.getAll().subscribe($employee => {
        this.employeePhStore.set($employee);
        observer.next($employee);
        observer.complete();
      });
    });
  }

}

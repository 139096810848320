import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Offer } from '../../../../app/shared/models/offer/offer.model';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/00-settings/app.config';


@Injectable({
  providedIn: 'root'
})
export class OfferDataService {

  constructor( private http: HttpClient) {}

  getAll(): Observable<Offer[]> {
    return this.http.get<Offer[]>(APP_CONFIG.api_endpoint + `offers_post_type?per_page=100`);
    
  }

  getById(id: number): Observable<Offer> {
    return this.http.get<Offer>(APP_CONFIG.api_endpoint + `offers_post_type/${id}`);
  }

  getBySlug(slug: string): Observable<Offer> {
    return this.http.get<Offer>(APP_CONFIG.api_endpoint + `offers_post_type?slug=${slug}`)[0];
  }
}

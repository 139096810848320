import { Injectable } from '@angular/core';
import { EmployeeTermStore } from './employee-term.store';
import { EmployeeTerm } from '../../../../app/shared/models/emplyee/employee-term.model';
import { Observable, Observer, forkJoin } from 'rxjs';
import { EmployeeDeTaxonomyDataService } from '../../data-services/employee-de/employee-de-taxonomy.service';
import { EmployeePhTaxonomyDataService } from '../../data-services/employee-ph/employee-ph-taxonomy.service';
import { concat } from 'lodash';

@Injectable({ providedIn: 'root' })
export class EmployeeTermService {

  constructor(private employeeTermStore: EmployeeTermStore,
              private employeeDeTaxonomyDataService: EmployeeDeTaxonomyDataService,
              private employeePhTaxonomyDataService: EmployeePhTaxonomyDataService) {
  }

  // init(): Observable<EmployeeTerm[]> {
  //   return new Observable((observer: Observer<EmployeeTerm[]>) => {
  //     this.employeeDeTaxonomyDataService.getAll().subscribe($employeeTerm => {
  //       this.employeeDeTermStore.set($employeeTerm);
  //       observer.next($employeeTerm);
  //       observer.complete();
  //     });
  //   });
  // }

  init(): Observable<EmployeeTerm[]> {
    return new Observable((observer: Observer<EmployeeTerm[]>) => {
      forkJoin([this.employeeDeTaxonomyDataService.getAll(), this.employeePhTaxonomyDataService.getAll()])
      .subscribe( ([$employeeDeTaxonomy, $employeePhTaxonomy]) => {
        const $employeeTaxonomy = concat($employeeDeTaxonomy, $employeePhTaxonomy);
        this.employeeTermStore.set($employeeTaxonomy );
        observer.next($employeeTaxonomy );
        observer.complete();
      });
    });
  }
}

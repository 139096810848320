import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainComponent } from './main.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { RouterModule } from '@angular/router';
import { MainMenuComponent } from './shared/components/main-menu/main-menu.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule
  ]
})
export class MainModule {
}

import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries } from '@datorama/akita';
import { CaseTermStore, CaseTermState } from './case-term.store';
import { CaseTerm } from '../../../../app/shared/models/case/case-term.model';
import { Observable } from 'rxjs';
import { CaseQuery } from '../case/case.query';
import { map } from 'rxjs/operators';
import { find, map as _map, filter, includes, merge, clone } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CaseTermQuery extends QueryEntity<CaseTermState, CaseTerm> {

  constructor(protected store: CaseTermStore,
              private caseQuery: CaseQuery) {
    super(store);
  }

  getOnlyParentsByLang(lang: string): Observable<CaseTerm[]> {
    return this.selectAll({
      filterBy: [
        term => term.parent === 0,
        term => term.ffuf_term_polylang.current_lang === lang
      ]
    });
  }

  selctTermBySlugAndLangWithLayouts(slug: string, lang: string): Observable<CaseTerm> {
    return combineQueries(
      [
        this.selectEntity(entity => entity.slug === slug && entity.ffuf_term_polylang.current_lang === lang),
        this.caseQuery.selectAll({filterBy: entity => entity.ffuf_polylang.current_lang === lang  })
      ]
    ).pipe(
      map(([caseTerm, posts]) => {
        return {
          ...caseTerm,
          $$postsLayouts: _map(caseTerm.ffuf_term_acf.layouts, layout => {
            const newLayout = clone(layout);
            if (newLayout.acf_fc_layout === 'full_width') {
              newLayout.case = find(posts, ['id', layout.case]);
            } else {
              newLayout.case_small = find(posts, ['id', layout.case_small]);
              newLayout.case_big = find(posts, ['id', layout.case_big]);
            }
            return newLayout;
          })
        };
      })
    );
  }

}

import { Injectable } from '@angular/core';
import { Store, StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { EmployeePh } from '../../../../app/shared/models/emplyee/employee-ph.model';

export interface EmployeePhState extends EntityState<EmployeePh> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employeePh' })
export class EmployeePhStore extends EntityStore<EmployeePhState, EmployeePh> {

  constructor() {
    super();
  }

}


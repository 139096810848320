import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicePhStore } from './service-ph.store';
import { Service } from '../../../../app/shared/models/service/service.model';
import { ServicePhDataService } from '../../data-services/service-ph/service-ph.service';
import { Observable, Observer } from 'rxjs';
import { ServicePhQuery } from './service-ph.query';

@Injectable({ providedIn: 'root' })
export class ServicePhService {

  constructor(private servicePhStore: ServicePhStore,
              private servicePhQuery: ServicePhQuery,
              private servicePhDataService: ServicePhDataService) {
  }

  init(): Observable<Service[]> {
    return new Observable((observer: Observer<Service[]>) => {
      this.servicePhDataService.getAll().subscribe( $servicePh => {
        this.servicePhStore.set($servicePh);
        observer.next($servicePh);
        observer.complete();
      });
    });
  }

}

import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { EmployeeDeStore, EmployeeDeState } from './employee-de.store';
import { EmployeeDe } from '../../../../app/shared/models/emplyee/employee-de.model';

@Injectable({ providedIn: 'root' })
export class EmployeeDeQuery extends QueryEntity<EmployeeDeState, EmployeeDe> {

  constructor(protected store: EmployeeDeStore) {
    super(store);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CaseStore } from './case.store';
import { Observable, Observer } from 'rxjs';
import { Case } from '../../../../app/shared/models/case/case.model';
import { CaseDataService } from '../../data-services/case/case.service';

@Injectable({ providedIn: 'root' })
export class CaseService {

  constructor(private caseStore: CaseStore,
              private caseDataService: CaseDataService) {
  }

  init(): Observable<Case[]> {
    return new Observable((observer: Observer<Case[]>) => {
      this.caseDataService.getAll().subscribe($cases => {
        this.caseStore.set($cases);
        observer.next($cases);
        observer.complete();
      });
    });
  }
}

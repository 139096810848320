import { AnalyticsConfiguration } from '@ffufm/ngx-utils';
import { environment } from '../../environments/environment';

export const ANALYTICS_CONFIG: AnalyticsConfiguration = {
    enabled: false,
    googleAnalytics: {
      enabled: false,
      id: environment.analytics['googleAnalyticsId']
    },
    googleTag: {
      enabled: false,
      id: environment.analytics['googleAnalyticsId']
    },
    googleTagManager: {
      enabled: false,
      id: environment.analytics['googleTagManagerId']
    },
    googleOptimize: {
      enabled: false,
      id: environment.analytics['googleOptimizeId']
    }
  };

import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { Page } from '../../../../app/shared/models/page.model';
import { Service } from '../../../../app/shared/models/service/service.model';
import { ServiceDeQuery } from '../../../../app/core/state/service-de/service-de.query';
import {forEach} from 'lodash';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import { SeoService } from 'src/app/core/services/seo.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  services: Service[];
  isodd: Boolean = false;

  lottieConfig0: Object;
  lottieConfig1: Object;
  lottieConfig2: Object;
  lottieConfig3: Object;
  lottieConfig4: Object;
  lottieConfig5: Object;
  anim: any;
  directions: number[] = [];
  playing: boolean[] = [];
  animations: any[] = [];
  elRef: ElementRef;
  lastScrollTop: number;
  isBrowser: boolean;
  contentPath: string;

  constructor( @Inject(WINDOW) private window: Window,
               @Inject(PLATFORM_ID) private platformId,
               private serviceDeQuery: ServiceDeQuery,
               private titleService: Title,
               private seo: SeoService,
               private router: Router) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {

    this.titleService.setTitle('Unsere Leistungen');
    this.services = this.serviceDeQuery.getAllByLang('de');
    this.lastScrollTop = 0;

    this.seo.setCanonicalUrl();
    this.seo.setSocialMediaTags({
      title: 'Unsere Leistungen',
      description: 'Feil, Feil & Feil bewegt sich zwischen Digitalisierung von Prozessen, dem Einführen neuer Geschäftsmodelle und dem Sammeln paxistauglicher Erfahrung in den Bereichen Internet of Things sowie Robotik.',
      image: 'https://ffuf.de/assets/img/shared/katalog_hover.png'
    });

    this.lottieConfig0 = {
      path: ' assets/animation/app.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig1 = {
      path: 'assets/animation/cloud.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig2 = {
      path: 'assets/animation/robot.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig3 = {
      path: ' assets/animation/app.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig4 = {
      path: 'assets/animation/cloud.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };

    this.lottieConfig5 = {
      path: 'assets/animation/robot.json',
      renderer: 'canvas',
      autoplay: false,
      loop: false
    };
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

  get changeOdd(): Boolean {
    this.isodd = !this.isodd;
    return true;
  }

  handleAnimation(anim: any): void {
    this.anim = anim;
    this.animations.push(this.anim);
    this.directions.push(1);
    this.playing.push(false);
  }

  changeDir(dir: number): number {
    return (dir === 1 ? -1 : 1);
  }

  stop(): void {
    this.anim.stop();
  }

  play(): void {
      this.anim.play();
  }

  pause(): void {
      this.anim.pause();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.animations[0].setDirection(this.directions[0]);
      this.animations[0].play();

      this.animations[3].setDirection(this.directions[0]);
      this.animations[3].play();
    }, 1500);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any): void {
    if(this.isBrowser) {
      const scrollPosition = this.window.pageYOffset;
      const windoHeight = this.window.innerHeight;

      const elements = document.getElementsByClassName('animation') as HTMLCollectionOf<HTMLElement>;
      const scrollDir = (this.window.pageYOffset > this.lastScrollTop ? 'down' : 'up');
      this.lastScrollTop = this.window.pageYOffset;

      forEach(elements, (element, key) => {
        // tslint:disable: max-line-length
        // tslint:disable: restrict-plus-operands
        if ( elements[key].offsetHeight + elements[key].offsetTop <= innerHeight + scrollPosition &&
          scrollPosition <= elements[key].offsetTop && key !== 0 ) {
          if ( scrollDir === 'down') {
            if (this.playing[key] === false) {
              this.playing[key] = true;
              this.animations[key].setDirection(this.directions[key]);
              this.animations[key].play();
            }
          }
        }
      });
    }
  }

}

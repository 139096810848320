import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceDelete'
})
export class SpaceDeletePipe implements PipeTransform {

  transform(text: any): string {
    text = text.replace(/\s+/g, '-');
    return text.toLowerCase();
  }

}

import { Injectable } from '@angular/core';
import { OffersStore } from './offers.store';
import { Offer } from '../../../../app/shared/models/offer/offer.model';
import { OffersQuery } from './offers.query';
import { OfferDataService } from '../../data-services/offer/offer.service';
import { Observable, Observer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OffersService {

  constructor(private offersStore: OffersStore,
              private offersQuery: OffersQuery,
              private offerDataService: OfferDataService) {
  }

  init(): Observable<Offer[]> {
    return new Observable((observer: Observer<Offer[]>) => {
      this.offerDataService.getAll().subscribe( $offers => {
        this.offersStore.set($offers);
        observer.next($offers);
        observer.complete();
      });
    });
  }

}

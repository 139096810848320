import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent, NgcCookieConsentService } from 'ngx-cookieconsent';

import { UiQuery } from './core/state/ui';

@Component({
  selector: 'app-root',
  template: `
    <app-loader *ngIf="(appLoadingState$ | async)"></app-loader>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  appLoadingState$: Observable<boolean>;

  // private popupOpenSubscription: Subscription;
  // private popupCloseSubscription: Subscription;
  // private initializeSubscription: Subscription;
  // private statusChangeSubscription: Subscription;
  // private revokeChoiceSubscription: Subscription;
  // private noCookieLawSubscription: Subscription;

  constructor(
    private uiQuery: UiQuery,
    // private ccService: NgcCookieConsentService
  ) {
  }

  ngOnInit(): void {
    this.appLoadingState$ = this.uiQuery.isAppLoading$;

    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });
  }

  // ngOnDestroy() {
  //   // unsubscribe to cookieconsent observables to prevent memory leaks
  //   this.popupOpenSubscription.unsubscribe();
  //   this.popupCloseSubscription.unsubscribe();
  //   this.initializeSubscription.unsubscribe();
  //   this.statusChangeSubscription.unsubscribe();
  //   this.revokeChoiceSubscription.unsubscribe();
  //   this.noCookieLawSubscription.unsubscribe();
  // }
}

import { Injectable } from '@angular/core';

import { UiStore } from './ui.store';

@Injectable({providedIn: 'root'})
export class UiService {

  constructor(private store: UiStore) {
  }

  update(key: string, state: any): void {
    this.store.update({[key]: state});
  }

}

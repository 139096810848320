import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiState {
  appLoading: boolean;
  navbarBackground: string;
  navbarLogo: string;
  menuBackgroundColor: string;
  showArrow: boolean;
  firstVideoLoad: boolean;
}

export function createInitialState(): UiState {
  return {
    appLoading: true,
    navbarBackground: 'bright',
    navbarLogo: 'red',
    showArrow: false,
    firstVideoLoad: true,
    menuBackgroundColor: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {
  constructor() {
    super(createInitialState());
  }
}

import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Seo } from 'src/app/shared/models/seo.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class SeoService {

    constructor( private meta: Meta, @Inject(DOCUMENT) private dom) { }

    setCanonicalUrl(): void {
        const canUrl = this.dom.URL;
        const htmlLink: HTMLLinkElement = this.dom.createElement('link');
        htmlLink.setAttribute('rel', 'canonical');
        htmlLink.setAttribute('href', canUrl);
        this.dom.head.appendChild(htmlLink);
    }

    setSocialMediaTags(tag: Seo): void {

        this.meta.updateTag({name: 'description', content: tag.description.replace(/<[^>]*>?/gm, '')});

        // open graphs
        this.meta.updateTag({property: 'og:title', content: tag.title});
        this.meta.updateTag({property: 'og:description', content: tag.description.replace(/<[^>]*>?/gm, '')});
        this.meta.updateTag({property: 'og:url', content: this.dom.URL});
        this.meta.updateTag({property: 'og:image', content: tag.image});
        this.meta.updateTag({ property: 'og:type', content: tag.type });

        // twitter cards
        this.meta.updateTag({name: 'twitter:title', content: tag.title});
        this.meta.updateTag({name: 'twitter:description', content: tag.description.replace(/<[^>]*>?/gm, '')});
        this.meta.updateTag({name: 'twitter:image', content: tag.image});
        this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServicePhStore, ServicePhState } from './service-ph.store';
import { Service } from '../../../../app/shared/models/service/service.model';

@Injectable({
  providedIn: 'root'
})
export class ServicePhQuery extends QueryEntity<ServicePhState, Service> {

  constructor(protected store: ServicePhStore) {
    super(store);
  }

  getAllByLang(lang: string): Service[] {
    return this.getAll().filter(service => service.ffuf_polylang.current_lang === lang);
  }

}

import { I18nGlobalConfigInterface } from '@ffufm/ngx-i18n';
import { SerdesGlobalConfigInterface } from '@ffufm/ngx-serdes';
import { UiriGlobalConfigInterface } from '@ffufm/ngx-uiri';

import { APP_CONFIG } from './app.config';

// import { setTheme } from 'ngx-bootstrap';

// setTheme('bs3');

export const UIRI_CONFIG: UiriGlobalConfigInterface = {
  enabled: false,
  translation: false,
  alertType: 'toast',
  toastConfig: {
    positionClass: 'toast-bottom-right'
  }
};

export const SERDES_CONFIG: SerdesGlobalConfigInterface = {
  enabled: false
};

export const I18N_CONFIG: I18nGlobalConfigInterface = {
  enabled: false,
  version: APP_CONFIG.version,
  countries: {
    enabled: false, // Set to true if your app uses countries picker
    list: [], // Add list of available countries
    useCountryLanguages: false // Set to true if you want to use default country languages
  },
  languages: {
    enabled: false, // Set to false if your app uses country languages
    list: ['de_DE'] // Add list of available languages
  },
  registerLocales: false // enables localization for currency,date,number and percent pipe
};

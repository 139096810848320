import { Component, OnInit } from '@angular/core';
import { JobsQuery } from '../core/state/jobs/jobs.query';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Job } from '../shared/models/job/job.model';
import { Offer } from '../shared/models/offer/offer.model';
import { Observable } from 'rxjs';
import { OffersQuery } from '../core/state/offers/offers.query';
import { UiService } from '../core/state/ui';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../core/services/seo.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  slug: string;
  job: Job;
  offers$: Observable<Offer[]>;
  file: boolean;
  url: string;
  fbUrl: string;
  contentPath: string;


  constructor(private jobsQoery: JobsQuery,
              private route: ActivatedRoute,
              private offersQuery: OffersQuery,
              private uiService: UiService,
              private titleService: Title,
              private meta: Meta,
              private router: Router,
              private seo: SeoService) { }

  ngOnInit(): void {
    this.file = true;

    this.route.params.subscribe((params: ParamMap) => {
      this.slug = params['slug'];
      this.jobsQoery.selectLoading().subscribe((loading) => {
        if (!loading) {
          this.jobsQoery.selectEntity(job => job.slug === this.slug)
                        .subscribe((job) => {
                          this.job = job;
                          if (this.job === undefined) {
                            this.router.navigate(['']);
                            return;
                          }
                          this.titleService.setTitle(job.title.rendered);
                          // tslint:disable: restrict-plus-operands
                          this.url = 'https://ffuf.de/share.php?id=' + job.id;
                          this.fbUrl = 'https://ffuf.de/share-fb.php?id=' + job.id;
                          this.uiService.update('navbarLogo', this.job.ffuf_acf.logoColor);
                          this.uiService.update('menuBackgroundColor', this.job.ffuf_acf.menuBackgroundColor);

                          this.seo.setCanonicalUrl();
                          this.seo.setSocialMediaTags({
                            title: this.job.title.rendered,
                            description: this.job.ffuf_acf.description,
                            image: this.job.ffuf_acf.teaser_bild
                          });

                        });
        }
      });
    });

    this.uiService.update('navbarBackground', 'transparent');
    this.uiService.update('showArrow', true);

    this.offers$ = this.offersQuery.selectAllByLang('de');
    this.contentPath = this.router.url.replace(/\//g, '_');
  }

}

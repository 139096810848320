import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import { initializeAnalytics } from '@ffufm/ngx-utils';

import { AppModule } from './app/app.module';
import { ANALYTICS_CONFIG } from 'src/config/00-settings/ga.config';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './icons';

if (environment.name !== 'local') {
  enableProdMode();
  enableAkitaProdMode();
}

if (ANALYTICS_CONFIG.enabled) {
  initializeAnalytics(ANALYTICS_CONFIG);
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap();
  });
}

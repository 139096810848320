import { Component, OnInit } from '@angular/core';
import { Country, InternalizationService } from '@ffufm/ngx-i18n';
import { TrackByFnService } from '@ffufm/ngx-utils';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.css']
})
export class CountryPickerComponent implements OnInit {
  countries: Country[];
  selectedCountry: Country;

  constructor(private i18nService: InternalizationService, public trackByFnService: TrackByFnService) {}

  ngOnInit(): void {
    this.countries = this.i18nService.getCountries();
    this.selectedCountry = this.i18nService.getSelectedCountry();
  }

  onCountryChange(): void {
    this.i18nService.setSelectedCountry(this.selectedCountry.shortCode);
  }
}
